@charset "utf-8";


/* Reset */

html button {
  border: 0 none;
  background: transparent;
  cursor: pointer
}


/* loading */

.loading {
  display: block;
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  background: #f4fcfe url('../images/common/ico_loading.gif') no-repeat center center;
  background-size: auto 35px
}

.loading span {
  display: none
}


/* popup */

.popup_dim {
  visibility: hidden;
  left: -9999px;
  background: rgba(0, 0, 0, .5);
  opacity: 0;
  top: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: 5;
  position: fixed;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.popup_dim.on {
  visibility: visible;
  left: 0;
}

.popup_dim.ani {
  opacity: 1;
}

.popup_panel {
  visibility: hidden;
  left: -9999px;
  position: fixed;
  top: 50%;
  background: #fff;
  z-index: 20;
  border: 1px solid #bfbfbf;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.popup_panel.on {
  visibility: visible;
  left: 50%;
}

.popup_panel.ani {
  opacity: 1;
}

.popup_alert {
  width: 270px;
  margin-left: -135px;
  background: #e7e7e7;
  z-index: 10000;
  border-radius: 8px
}

.popup_alert .popup_alert_in {
  padding: 12px 5px;
  text-align: center
}

.popup_alert .popup_alert_in h3 {
  padding-top: 4px;
  font-size: 16px;
  font-weight: 700
}

.popup_alert .popup_alert_in p {
  font-size: 13px;
  padding: 10px 0
}

.popup_alert .btn_pop_menu {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #1295ed;
  border-top: 1px solid #d8d8d8;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.popup_alert .btn_pop_menu button {
  display: block;
  padding: .8em 0;
  border-left: 1px solid #d8d8d8;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #1295ed;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
}

.popup_alert .btn_pop_menu button:first-child {
  border-left: 0;
}

.popup_alert .btn_pop_menu button.btn_pop_cancel {
  font-weight: 400;
}

.popup_alert .btn_pop_close {
  position: absolute;
  top: 8px;
  right: 5px;
  display: block;
  width: 30px;
  height: 30px;
  background: url('../images/common/btn_pop_close.png') no-repeat center center;
  background-size: 15px auto
}

.popup_alert .btn_pop_close span {
  display: none
}

.popup_loading {
  display: table;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  border: 0;
  background: rgba(255, 255, 255, .8);
  z-index: 100;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.popup_loading.on {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.popup_loading>p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #777;
}

.popup_loading .spinner {
  display: block;
  margin: 0 auto;
}

.content-header>h1 {
	color: #367fa9;
}
.table-responsive {
	.dataTables_wrapper.form-inline {
		.row {
			label,
			input,
			.input-group {
				display: inline-block;
				vertical-align: top;
				margin-bottom: 6px;
			}
			.input-group {
				font-size: 15px;
			}
			.input-group input,
			label input {
				margin-bottom: 0;
			}
			label {
				margin-right: 3px;
			}
			.ui-datepicker-trigger {
				vertical-align: middle;
				margin-top: -1px;
			}
			select.form-control {
				text-align: left;
			}
			.btn {
				margin-top: -2px;
				margin-bottom: -2px;
			}
			.btn-primary {
				background-color: #666;
				border-color: #666;
				&:focus,
				&:hover {
					background-color: #555;
					border-color: #555;
				}
			}
			.btn-success {
				background-color: #e21b22;
				border-color: #e21b22;
				&:focus,
				&:hover {
					background-color: #cb191f;
					border-color: #cb191f;
				}
			}
			.col-sm-6 {
				font-size: 0;
			}
			.col-sm-6 + .col-sm-6 {
				float: right;
				width: inherit !important;
			}
		}
		.row+.row {
			.btn-success {
				background-color: #367fa9;
				border-color: #367fa9;
				&:focus,
				&:hover {
					background-color: #307094;
					border-color: #307094;
				}
			}
		}
	}
}
.modal-header {
	padding-left: 20px;
	padding-right: 20px;
}
.modal-body .form-horizontal {
	.row {
		.input-group {
			display: inline-block;
			padding-top: 4px;
			padding-left: 5px;
			vertical-align: middle;
			white-space: nowrap;
			input {
				display: inline-block;
				margin-left: 5px;
			}
		}
		.btn.btn-primary {

		}
	}
}
.form-control.input-sm.from_data,
.form-control.input-sm.to_date {
	float: none;
	display: inline-block;
	width: 150px;
	background-color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.form-control.input-sm.to_date {
	position: relative;
	margin-left: 3px;
	&+img {
		margin-right: 10px;
	}
}
.sidebar-toggle,
.ui-tooltip.ui-widget.ui-corner-all.ui-widget-content {
	display: none;
}
.skin-blue .sidebar-menu>li {
	>a {
  	border-left-width: 3px;
  	padding-left: 18px;
		i {
			display: none;
		}
	}
  >.treeview-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #2c3b41;
    font-size: 12px;
    &>li>a {
      * {
        font-size: 12px;
      }
    }
  }
	&.header {
		padding-left: 21px;
		padding-right: 34px;
		i {
			margin-top: 1px;
			vertical-align: middle;
		}
	}
}
html,
body.skin-blue.sidebar-mini {
	height: 100%;
	.wrapper {
		height: 100% !important;
		-webkit-box-shadow: 0 500px 0 0 #222d32;
		box-shadow: 0 500px 0 0 #222d32;
	}
}
/* 회원가입 */
.wrapper .form-horizontal .wrapper {
	width: 1000px;
	margin: 60px auto;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	&>div {
		padding: 30px;
	}
	.content-header > h1 {
		padding-left: 6px;
		color: #2c3b41 !important;
		.fa {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0px;
		}
	}
	.bg-aqua,
	.callout.callout-info,
	.alert-info,
	.label-info,
	.modal-info .modal-body {
		-webkit-border-radius: 0px;
		border-radius: 0px;
		background-color: #2c3b41 !important;
		border-color: #3c8dbc !important;
	}
	.pad.margin.no-print {
		margin: 0 !important;
		padding: 20px 15px 0;
		.fa.fa-fw.fa-exclamation-circle {
			display: none;
		}
		font {
			float: left;
			display: inline-block;
			padding: 1px 5px 1px 3px;
			text-align: left;
			background-color: #666666 !important;
			font-size: 12px;
			font-weight: 400 !important;
		}
	}
	.fa.fa-fw.fa-star {
		display: inline-block;
		margin-right: 3px;
		vertical-align: middle;
		font-size: 10px !important;
	}
	.box.box-info {
		padding-top: 10px;
		padding-right: 10px;
		border-top: 1px solid #eee !important;
		.box-body {
			padding-bottom: 0;
		}
		.box-footer {
			padding-top: 0;
		}
	}
	.box.box-info,
	.box-body {
		-webkit-border-radius: 0px;
		border-radius: 0px;
	}
	.form-group {
		.col-sm-1 {
			width: 20%;
			padding-left: 24px;
		}
		.col-sm-11 {
			width: 80%;
			font-size: 0;
			label {
				width: auto !important;
				margin-right: 3px;
				margin-bottom: 0;
				vertical-align: middle;
			}
			select.form-control {
				padding-left: 8px;
			}
		}
		.fa.fa-fw.fa-star {
			margin-top: -2px;
			color: #666;
		}
	}
}
table.dataTable thead>tr>th {
  color: white;
  vertical-align: middle;
}
.table.table-striped.table-bordered.table-hover.dataTable {
	border-collapse: collapse !important;
	border: 1px solid #ddd ;
	thead tr[role="row"] th[colspan="5"]{
		display: none;
		padding-top: 0;
		padding-bottom: 0;
		border-top: 0 none;
		border-bottom: 0 none;
	}
	th {
  	background-color: #566165;
	}
	th,
	td {
		border-left: 0 none;
		border-bottom: 0 none;
	}
	td {
		vertical-align: middle;
		border: 1px solid #ddd;
		font-weight: 600;
		font-size: 14px;
		label {
			margin-bottom: 0;
		}
		.select.type_01 {
			min-width: 170px;
			select {
				width: 100%;
			}
		}
		.form-group .col-sm-6 {
			padding-left: 0;
			input[type="radio"] {
				display: inline-block;
				vertical-align: middle;
				margin-top: -2px;
				margin-right: 5px;
				margin-left: 20px;
			}
			input[type="radio"]:first-child {
				margin-left: 0;
			}
		}
	}
}

.auto_complete {
  position: relative;
  overflow: visible !important;
  z-index: 3 !important;
  ul,
  li {
  	list-style: none;
  	margin: 0;
  	padding: 0;
  }
  .auto_complete_box {
    display: none;
    position: absolute;
    width: 100%;
    background-color: #2c3b41;
    z-index: 100;
    a {
      display: block;
      padding: 6px 6px 6px 11px;
      color: #fff;
      white-space: normal;
      line-height: 1.2em;
      font-weight: 400;
      font-size: 12px;
      &.on,
      &:hover {
        background-color: #1e282c;
      }
    }
  }
  .auto_complete_box.on,
  input:focus+.auto_complete_box {
    display: block;
  }
}
